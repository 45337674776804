<template>
  <div class="d-flex">
    <v-btn v-if="!noEdit" class="action-btn" title="Editar" @click="$emit('edit')" small icon>
      <svg style="width: 20px; height: 20px" viewBox="0 0 24 24">
        <path
          fill="currentColor"
          d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z"
        />
      </svg>
    </v-btn>
    <v-btn v-if="!noDelete" class="action-btn" title="Eliminar" @click="$emit('remove')" small icon>
      <svg style="width: 20px; height: 20px" viewBox="0 0 24 24">
        <path
          fill="currentColor"
          d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z"
        />
      </svg>
    </v-btn>
    <v-btn v-if="dragg" class="action-btn dragg-item" title="Mover" @click="() => {}" small icon>
      <svg style="width: 20px; height: 20px" width="24" height="24" viewBox="0 0 24 24">
        <path fill="currentColor" d="M24 12l-6-5v4h-5v-5h4l-5-6-5 6h4v5h-5v-4l-6 5 6 5v-4h5v5h-4l5 6 5-6h-4v-5h5v4z" />
      </svg>
    </v-btn>
    <template v-if="draggControls">
      <v-menu left bottom :offset-y="true" close-on-click>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" small> 
            <svg style="width: 20px; height: 20px"  viewBox="0 0 24 24" fill-rule="evenodd" clip-rule="evenodd"><path d="M12 16c1.656 0 3 1.344 3 3s-1.344 3-3 3-3-1.344-3-3 1.344-3 3-3zm0 1c1.104 0 2 .896 2 2s-.896 2-2 2-2-.896-2-2 .896-2 2-2zm0-8c1.656 0 3 1.344 3 3s-1.344 3-3 3-3-1.344-3-3 1.344-3 3-3zm0 1c1.104 0 2 .896 2 2s-.896 2-2 2-2-.896-2-2 .896-2 2-2zm0-8c1.656 0 3 1.344 3 3s-1.344 3-3 3-3-1.344-3-3 1.344-3 3-3zm0 1c1.104 0 2 .896 2 2s-.896 2-2 2-2-.896-2-2 .896-2 2-2z"/></svg>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="$emit('sendToTheInit')">
            <v-list-item-title>
              <small>Colocar al inicio</small>
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="$emit('sendToTheEnd')">
            <v-list-item-title>
              <small>Colocar al final</small>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    noEdit: Boolean,
    noDelete: Boolean,
    dragg: Boolean,
    draggControls: Boolean,
  },
}
</script>

<style lang="scss" scoped>
.dragg-item {
  cursor: move;
}
</style>
