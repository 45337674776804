import { ref, computed } from "@vue/composition-api";
import store from "@/store/index"

const useTransparencySubTypes = (id_types) => {

  // base data
  const dialog = ref(false);
  const transparencySubTypesEdited = ref(null);
  const transparencySubTypes = ref([]);
  const search = ref("");
  const loading = ref(false);

  // computed properties
  const titleDialog = computed(() =>
    transparencySubTypesEdited.value ? "Editar Subtipo de Transparencia" : "Agregar Subtipo de Transparencia"
  );
  const subtitleDialog = computed(() =>
    transparencySubTypesEdited.value ?
      "Rellene los campos correctamente para modificar los datos del subtipo" :
      "Rellene los campos correctamente para validar los datos del subtipo"
  );

  const closeDialog = () => {
    dialog.value = false;
    transparencySubTypesEdited.value = null;
  }

  // methods
  const createTransparencySubTypes = async (transparencySubTypes) => {
    loading.value = true;
    const { data, status } = await store.dispatch("createTransparencySubTypes", transparencySubTypes)
    loading.value = false;
    if (status != 200 && status != 201) return;
    await getIdTypes();
    closeDialog();
  }

  const updateTransparencySubTypes = async (transparencySubTypes) => {
    loading.value = true;
    const { data, status } = await store.dispatch("updateTransparencySubTypes", transparencySubTypes)
    loading.value = false;
    if (status != 200 && status != 201) return;
    await getIdTypes();
    closeDialog();
  }

  const removeTransparencySubTypes = async (transparencySubTypes) => {
    const val = await store.dispatch("generateConfirmDialog", {});
    if (!val) return;
    const { data, status } = await store.dispatch("removeTransparencySubTypes", transparencySubTypes)
    if (status != 200 && status != 201 && status != 204) return;
    await getIdTypes();
  }

  const getIdTypes = async () => {
    loading.value = true;
    const { data, status } = await store.dispatch('getIdTypes', id_types);
    loading.value = false;
    if (status != 200 && status != 201 && status != 204) return;
    transparencySubTypes.value = data;
  }
  getIdTypes();


  return {
    dialog,
    transparencySubTypesEdited,
    transparencySubTypes,
    search,
    loading,
    // computed
    titleDialog,
    subtitleDialog,
    // methods
    changeSearch(ev) {
      search.value = ev
    },
    openDialog() {
      dialog.value = true;
    },
    openEdit(transparencySubTypes) {
      dialog.value = true;
      transparencySubTypesEdited.value = { ...transparencySubTypes };
    },
    openRemove: removeTransparencySubTypes,
    closeDialog,
    createTransparencySubTypes,
    updateTransparencySubTypes
  };
}

export default useTransparencySubTypes;
