<template>
  <v-card>
    <TransparencySubTypesHeader @openToAdd="openDialog" @search="changeSearch"></TransparencySubTypesHeader>
    <DatatableTransparencySubTypes
      :transparencySubTypes="transparencySubTypes"
      :loading="loading"
      :search="search"
      @edit="openEdit"
      @remove="openRemove"
    ></DatatableTransparencySubTypes>
    <WDialog width="500" :dialog="dialog" :closeDialog="closeDialog" :title="titleDialog" :subtitle="subtitleDialog">
      <template v-slot:form-dialog>
        <FormTransparencySubTypes
          v-if="dialog"
          :loading="loading"
          :idTypes="id_types"
          :transparencySubTypesEdited="transparencySubTypesEdited"
          @beforeUpdate="updateTransparencySubTypes"
          @beforeCreate="createTransparencySubTypes"
        ></FormTransparencySubTypes>
      </template>
    </WDialog>
  </v-card>
</template>

<script>
import DatatableTransparencySubTypes from './DatatableTransparencySubTypes.vue'
import FormTransparencySubTypes from '@/components/forms/FormTransparencySubTypes.vue'
import TransparencySubTypesHeader from './TransparencySubTypesHeader.vue'
import WDialog from '@/components/dialogs/WDialog.vue'
import useTransparencySubTypes from '@/composables/useTransparencySubTypes'

export default {
  components: {
    DatatableTransparencySubTypes,
    FormTransparencySubTypes,
    TransparencySubTypesHeader,
    WDialog,
  },
  setup(props, context) {
    const route = context.root.$route
    const id_types = route.params.id
    // Composition API
    const {
      dialog,
      transparencySubTypesEdited,
      transparencySubTypes,
      search,
      loading,
      // computed
      titleDialog,
      subtitleDialog,
      // methods
      changeSearch,
      openDialog,
      openEdit,
      openRemove,
      closeDialog,
      // crud
      createTransparencySubTypes,
      updateTransparencySubTypes,
    } = useTransparencySubTypes(id_types)
    // // console.log(id_types)

    return {
      dialog,
      transparencySubTypesEdited,
      transparencySubTypes,
      search,
      loading,
      // computed
      titleDialog,
      subtitleDialog,
      // methods
      changeSearch,
      openDialog,
      openEdit,
      openRemove,
      closeDialog,
      id_types,
      // crud
      createTransparencySubTypes,
      updateTransparencySubTypes,
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
