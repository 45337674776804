<template>
  <FormTemplate>
    <template v-slot:form-body>
      <v-form ref="formTransparencySubTypes" @submit.prevent="saveForm">
        <v-row>
          <v-col cols="12" md="12">
            <label for="Subtype">Nombre</label>
          </v-col>

          <v-col cols="12" md="12" class="pb-0">
            <v-text-field
              id="Subtype"
              v-model="transparencySubTypes.name"
              outlined
              dense
              placeholder="Nombre"
              :rules="required"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="12">
            <label for="Type">Tipo</label>
          </v-col>

          <v-col cols="12" class="py-2">
            <v-btn-toggle id="type" v-model="transparencySubTypes.type" tile color="deep-purple accent-3" group>
              <v-btn value="list"> Lista </v-btn>
              <v-btn value="table"> Tabla </v-btn>
              <v-btn value="cards"> Card </v-btn>
            </v-btn-toggle>
          </v-col>

          <v-col offset-md="3" cols="9">
            <v-btn type="submit" :disabled="loading" :loading="loading" color="primary"> Guardar </v-btn>
            <v-btn type="reset" @click="reset" class="mx-2" outlined> Limpiar </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </template>
  </FormTemplate>
</template>

<script>
import FormTemplate from '@/components/forms/FormTemplate.vue'
import { loadAPIImage } from '@/utils/images'

export default {
  components: {
    FormTemplate,
  },
  props: {
    transparencySubTypesEdited: Object,
    loading: Boolean,
    idTypes: String,
  },
  data() {
    return {
      required: [v => !!v || 'Requerido'],
      url: '',
      image: null,
      transparencySubTypes: this.transparencySubTypesEdited ? { ...this.transparencySubTypesEdited } : {},
    }
  },
  methods: {
    validateForm() {
      return this.$refs.formTransparencySubTypes.validate()
    },
    reset() {
      this.$refs.formTransparencySubTypes.resetValidation()
    },
    saveForm() {
      // // console.log('IdType = ', this.id_types);
      if (!this.validateForm()) return
      if (this.transparencySubTypesEdited) {
        let DTO = { ...this.transparencySubTypes, id_types: this.idTypes }
        this.$emit('beforeUpdate', DTO)
      } else {
        const DTO = { ...this.transparencySubTypes, id_types: this.idTypes }
        this.$emit('beforeCreate', DTO)
      }
    },
    loadImage(src) {
      return loadAPIImage(src)
    },
    onFileChange(e) {
      if (!e) return
      this.url = URL.createObjectURL(e)
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
